import './App.scss'
import { Routes, Route } from 'react-router-dom'
import Layout from "./components/Layout"
import Home from './components/Home'
import About from './components/Home/About'
import Contact from './components/Home/Contact'

function App () {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
        </Route>
      </Routes>
    </div>
  )
}

export default App
