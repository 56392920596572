// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-animate {
  display: inline-block;
  opacity: 0;
  animation: bounceIn 1s 1s;
  animation-fill-mode: forwards;
  min-width: 10px;
}

.text-animate-hover {
  min-width: 10px;
  display: inline-block;
  animation-fill-mode: both;
}
.text-animate-hover:hover {
  animation: rubberBand 1s;
  color: #C2DFEB;
}

.text-animate._1 {
  animation-delay: 0.1s;
}

.text-animate._2 {
  animation-delay: 0.2s;
}

.text-animate._3 {
  animation-delay: 0.3s;
}

.text-animate._4 {
  animation-delay: 0.4s;
}

.text-animate._5 {
  animation-delay: 0.5s;
}

.text-animate._6 {
  animation-delay: 0.6s;
}

.text-animate._7 {
  animation-delay: 0.7s;
}

.text-animate._8 {
  animation-delay: 0.8s;
}

.text-animate._9 {
  animation-delay: 0.9s;
}

.text-animate._10 {
  animation-delay: 1s;
}

.text-animate._11 {
  animation-delay: 1.1s;
}

.text-animate._12 {
  animation-delay: 1.2s;
}

.text-animate._13 {
  animation-delay: 1.3s;
}

.text-animate._14 {
  animation-delay: 1.4s;
}

.text-animate._15 {
  animation-delay: 1.5s;
}

.text-animate._16 {
  animation-delay: 1.6s;
}

.text-animate._17 {
  animation-delay: 1.7s;
}

.text-animate._18 {
  animation-delay: 1.8s;
}

.text-animate._19 {
  animation-delay: 1.9s;
}

.text-animate._20 {
  animation-delay: 2s;
}

.text-animate._21 {
  animation-delay: 2.1s;
}

.text-animate._22 {
  animation-delay: 2.2s;
}

.text-animate._23 {
  animation-delay: 2.3s;
}

.text-animate._24 {
  animation-delay: 2.4s;
}

.text-animate._25 {
  animation-delay: 2.5s;
}

.text-animate._26 {
  animation-delay: 2.6s;
}

.text-animate._27 {
  animation-delay: 2.7s;
}

.text-animate._28 {
  animation-delay: 2.8s;
}

.text-animate._29 {
  animation-delay: 2.9s;
}

.text-animate._30 {
  animation-delay: 3s;
}

.text-animate._31 {
  animation-delay: 3.1s;
}

.text-animate._32 {
  animation-delay: 3.2s;
}

.text-animate._33 {
  animation-delay: 3.3s;
}

.text-animate._34 {
  animation-delay: 3.4s;
}

.text-animate._35 {
  animation-delay: 3.5s;
}`, "",{"version":3,"sources":["webpack://./src/components/AnimatedLetters/index.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,UAAA;EACA,yBAAA;EACA,6BAAA;EACA,eAAA;AACJ;;AAEA;EACI,eAAA;EACA,qBAAA;EACA,yBAAA;AACJ;AACI;EACI,wBAAA;EACA,cAAA;AACR;;AAII;EACI,qBAAA;AADR;;AAAI;EACI,qBAAA;AAGR;;AAJI;EACI,qBAAA;AAOR;;AARI;EACI,qBAAA;AAWR;;AAZI;EACI,qBAAA;AAeR;;AAhBI;EACI,qBAAA;AAmBR;;AApBI;EACI,qBAAA;AAuBR;;AAxBI;EACI,qBAAA;AA2BR;;AA5BI;EACI,qBAAA;AA+BR;;AAhCI;EACI,mBAAA;AAmCR;;AApCI;EACI,qBAAA;AAuCR;;AAxCI;EACI,qBAAA;AA2CR;;AA5CI;EACI,qBAAA;AA+CR;;AAhDI;EACI,qBAAA;AAmDR;;AApDI;EACI,qBAAA;AAuDR;;AAxDI;EACI,qBAAA;AA2DR;;AA5DI;EACI,qBAAA;AA+DR;;AAhEI;EACI,qBAAA;AAmER;;AApEI;EACI,qBAAA;AAuER;;AAxEI;EACI,mBAAA;AA2ER;;AA5EI;EACI,qBAAA;AA+ER;;AAhFI;EACI,qBAAA;AAmFR;;AApFI;EACI,qBAAA;AAuFR;;AAxFI;EACI,qBAAA;AA2FR;;AA5FI;EACI,qBAAA;AA+FR;;AAhGI;EACI,qBAAA;AAmGR;;AApGI;EACI,qBAAA;AAuGR;;AAxGI;EACI,qBAAA;AA2GR;;AA5GI;EACI,qBAAA;AA+GR;;AAhHI;EACI,mBAAA;AAmHR;;AApHI;EACI,qBAAA;AAuHR;;AAxHI;EACI,qBAAA;AA2HR;;AA5HI;EACI,qBAAA;AA+HR;;AAhII;EACI,qBAAA;AAmIR;;AApII;EACI,qBAAA;AAuIR","sourcesContent":[".text-animate {\n    display: inline-block;\n    opacity: 0;\n    animation: bounceIn 1s 1s;\n    animation-fill-mode: forwards;\n    min-width: 10px;\n}\n\n.text-animate-hover{\n    min-width: 10px;\n    display: inline-block;\n    animation-fill-mode: both;\n\n    &:hover {\n        animation: rubberBand 1s;\n        color: #C2DFEB;\n    }\n}\n\n@for $i from 1 through 35 {\n    .text-animate._#{$i} {\n        animation-delay: #{$i /10}s;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
