import './index.scss'
import AnimatedLetters from '../../AnimatedLetters'
import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import LogoTitle from '../../../assets/images/logo-s.png'
import Blendable from '../../../assets/images/blendable-horizontal-one-color-no-tagline.png'
import Analytic from '../../../assets/images/AnalyticEngLogo.png'
import Intimitrons from '../../../assets/images/Intimitrons.png'

const About = () => {

    const [letterClass, setLetterClass] = useState('text-animate')

    useEffect(() => {
        setTimeout(() => {
            return setLetterClass('text-animate-hover')
        }, 3000)
    }, [])

    return (
        <>
            <div className='container about-page'>
                <div className='text-zone about'>
                    <h1>About Me
                    </h1>
                    <p>Hello! My name is Elena Yajure and I am a Software Engineering student at the University of Waterloo.</p>
                    <p>I am passionate about learning, and aim to squeeze as much information out of any opportunity that comes my way. I am strongly aware of the lucky position I am to be a young person in tech and aim to get as much out of any opportunity as possible. I love board games (my favorite's Dominion!) as well as a good puzzle.</p>
                    <p>I hope to someday make a positive social impact through technology, but for now my focus is to become the best software developer I can.</p>
                </div>
                <div className='experience'>
                    <h1>
                        Experience and Projects
                    </h1>
                    <ul>
                        <li>
                            <row>
                                <div>
                                    <h3>Blendable</h3>
                                    <p>I have been working for Blendable for around 5 months at the time of me updating this website, where I've acted as a fullstack developer, working on projects with both frontend and backend emphasis. Some of my favorite projects I've been able to work on are implementing dynamic searching throughout the companies various websites, and modifying how Blendables financial backend works to generate more accurate billing reports. I am incredibly grateful for my time with Blendable, as my coworkers are extremely willing to share what they know and support my learning.</p>
                                </div>
                                <img class="long-img" src={Blendable} />
                            </row>
                        </li>
                        <li>
                            <row>
                                <div>
                                    <h3>Analytic Engineering</h3>
                                    <p>I have worked on various projects for Analytic Engineering such as designing a dashboard to display data relating to Voltage, Field Strength, and Current Density accross various pathways using python, and creating a sign in page using React and Expo.</p>
                                </div>
                                <img style={{ height: 40 }} class="long-img" src={Analytic} />
                            </row>
                        </li>
                        <li>
                            <row>
                                <div>
                                    <h3>FRC team lead</h3>
                                    <p>I was lucky enough to be a part of an all girls FRC team, where I was a team lead for the programming and electrical team. During covid we had many online meetings, which lead to hours and hours of discussion between myself and my lovely mentors about all things programming - memory allocaton, recursion, complex data structures and more! I credit this team for sparking my interest in technology.</p>
                                </div>
                                <img class="box-img" src={Intimitrons} alt="developer" />
                            </row>
                        </li>
                        <li>
                            <row>
                                <div>
                                    <h3>This website!</h3>
                                    <p>I made this website using React and Sass. This is my second iteration on this website where I put more emphasis into having fun with it by adding animations. I also deployed this website starting with nothing but a blank linux server and a domain using Nginx, Certbot, and Docker. Uploading this website was definitely the most tedious part, but I am glad I started from scratch as I learned a lot.</p>
                                </div>
                            </row>
                        </li>
                    </ul>
                </div>
            </div>
            <Loader type="ball-pulse-rise" />
        </>
    )
}

export default About