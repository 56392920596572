import { Link } from 'react-router-dom'
import './index.scss'
import { useEffect, useState } from 'react'
import AnimatedLetters from '../AnimatedLetters'
import Loader from 'react-loaders'
import Cpp from '../../assets/images/cpp.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngular, faCss3, faGit, faGitAlt, faHtml5, faJava, faJsSquare, faLinux, faPython, faReact } from '@fortawesome/free-brands-svg-icons'
import { faC, faPlus } from '@fortawesome/free-solid-svg-icons'

const Home = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    const nameArray = ['E', 'l', 'e', 'n', 'a']
    // const hiArray = ['H', 'e', 'y', ' ', 't', 'h', 'e', 'r', 'e']

    useEffect(() => {
        setTimeout(() => {
            return setLetterClass('text-animate-hover')
        }, 4000)
    }, [])

    // const element = document.getElementById('test')
    // element.scrollIntoView({ behavior: 'smooth', block: 'start' })

    return (
        <>
            <div className="container">
                <div className="home-page">
                    <div className="text-zone">
                        <h1>Hey there, <br /> my name's <span class="name"><AnimatedLetters letterClass={letterClass} strArray={nameArray} idx={15} /></span>.
                            <br />
                            Nice to meet you!
                        </h1>
                        <h2>problem solver / learning enthusiast / slight perfectionist</h2>
                        <Link to="/contact" className='flat-button'>SAY HELLO</Link>
                    </div>

                    {/* Cube animation */}
                    <Link to="/about">
                        <div className='stage-cube-cont'>
                            <div className='cubespinner'>
                                <div className='face1'>
                                    <FontAwesomeIcon class='icon1' icon={faLinux} color='#000' />
                                </div>
                                <div className='face2'>
                                    <FontAwesomeIcon class='icon1' icon={faPython} color='#FFE873' />
                                    <FontAwesomeIcon class='icon2' icon={faPython} color='#4B8EE3' />
                                </div>
                                <div className='face3'>
                                    <FontAwesomeIcon class='icon1' icon={faHtml5} color='#28A4D9' />
                                    <FontAwesomeIcon class='icon2' icon={faHtml5} color='#28A4D9' />
                                    <FontAwesomeIcon class='icon3' icon={faHtml5} color='#28A4D9' />
                                </div>
                                <div className='face4'>
                                    <FontAwesomeIcon class='icon1' icon={faJsSquare} color='#EFD81D' />
                                    <FontAwesomeIcon class='icon2' icon={faJsSquare} color='#EFD81D' />
                                    <FontAwesomeIcon class='icon3' icon={faJsSquare} color='#EFD81D' />
                                    <FontAwesomeIcon class='icon4' icon={faJsSquare} color='#EFD81D' />
                                </div>
                                <div className='face5'>
                                    <FontAwesomeIcon class='icon1' icon={faReact} color='#5ED4F4' />
                                    <FontAwesomeIcon class='icon2' icon={faReact} color='#5ED4F4' />
                                    <FontAwesomeIcon class='icon3' icon={faReact} color='#5ED4F4' />
                                    <FontAwesomeIcon class='icon4' icon={faReact} color='#5ED4F4' />
                                    <FontAwesomeIcon class='icon5' icon={faReact} color='#5ED4F4' />
                                </div>
                                <div className='face6'>
                                    <FontAwesomeIcon class='icon1' icon={faGitAlt} color='#EC4D28' />
                                    <FontAwesomeIcon class='icon2' icon={faGitAlt} color='#EC4D28' />
                                    <FontAwesomeIcon class='icon3' icon={faGitAlt} color='#EC4D28' />
                                    <FontAwesomeIcon class='icon4' icon={faGitAlt} color='#EC4D28' />
                                    <FontAwesomeIcon class='icon5' icon={faGitAlt} color='#EC4D28' />
                                    <FontAwesomeIcon class='icon6' icon={faGitAlt} color='#EC4D28' />
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div >

            <Loader type="ball-pulse-rise" />
        </>
    )

}


export default Home